import { FC } from 'react';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import ActionEditorBase from '../ActionEditorBase';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import { useTranslation } from 'react-i18next';
import TranslatableInput from '../../../shared/form-control/TranslatableInput';

const AdHocFieldsEditor: FC<EditorProps> = (props) => {
  const { patchTranslations, action } = props;
  const { translations } = action;
  const { t } = useTranslation('form-builder');

  return (
    <div data-cy="ad-hoc-fields-editor">
      <ActionEditorBase {...props} altTitle={t('action-properties.description.ad-hoc-fields-title')}>
        <div className="mt-4">
          <TranslatableInput
            placeholder={t('action-properties.ad-hoc-fields.button-text')}
            label={t('action-properties.ad-hoc-fields.button-text')}
            translationKey="buttonText"
            translations={translations ?? {}}
            onTranslationsChange={patchTranslations}
          />
        </div>
      </ActionEditorBase>

      <ConditionsProperties {...props} hideRequired />

      <PreviewProperties {...props} enableDocumentNumbering hideTitle />
    </div>
  );
};

export default AdHocFieldsEditor;
